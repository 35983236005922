var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticStyle:{"padding-left":"70px"},attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"text-h6 mt-5 mb-3"},[_vm._v("Lista de Gastos en las caja activas")])])],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6","sm":"6","md":"3","lg":"2","xl":"2"}},[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.date,"first-day-of-week":0,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"mr-2 mt-0",attrs:{"label":"Fecha de consulta","prepend-icon":"mdi-calendar","persistent-hint":"","readonly":""},model:{value:(_vm.computedDateFormatted),callback:function ($$v) {_vm.computedDateFormatted=$$v},expression:"computedDateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalfecha),callback:function ($$v) {_vm.modalfecha=$$v},expression:"modalfecha"}},[_c('v-date-picker',{attrs:{"scrollable":"","first-day-of-week":1,"locale":"es-es"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalfecha = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.dialog.save(_vm.date);
                _vm.fechadatos();}}},[_vm._v(" Aplicar ")])],1)],1)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6","sm":"6","md":"3","lg":"2","xl":"2"}},[_c('v-select',{staticClass:"mt-0 pl-0 mr-0 ",staticStyle:{"max-width":"200px","margin-bottom":"-30px"},attrs:{"items":_vm.cajas,"item-text":'nombrecaja',"item-value":'id',"label":"Seleccione Caja","dense":"","outlined":"","background-color":"white","solo":"","light":""},on:{"change":_vm.seleccionarCaja1},model:{value:(_vm.cajapordefecto1),callback:function ($$v) {_vm.cajapordefecto1=$$v},expression:"cajapordefecto1"}})],1),_c('v-col',{staticClass:"pl-1 pr-0 pb-0",attrs:{"cols":"2","sm":"4","md":"1","lg":"1","xl":"1"}},[_c('v-btn',{staticClass:"ml-0",attrs:{"small":"","fab":"","dark":"","color":"orange"},on:{"click":function($event){return _vm.actualizarDatos()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-refresh ")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"xs":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('h4',{staticClass:"mb-2 "},[_c('v-icon',{staticClass:"mb-1",attrs:{"color":"blue accent-4"}},[_vm._v(" mdi-format-line-spacing ")]),_vm._v(" Gastos para la caja seleccionada -> "),_c('v-chip',{staticClass:"ml-2 mb-0",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.textodefecto)+" ")])],1),_c('v-data-table',{staticClass:"elavation-4",attrs:{"headers":_vm.headerMovimientos,"items":_vm.movimientosEntrada,"item-key":"orden","mobile-breakpoint":"0","height":"55vh","fixed-header":"","hide-default-footer":true,"dark":"","header-props":_vm.headerProps,"disable-pagination":"","no-data-text":"No hay registros","loading":_vm.cargaData,"loading-text":"Cargando datos... Por favor espere"},scopedSlots:_vm._u([{key:"item.imagen",fn:function(ref){
                var item = ref.item;
return [_c('v-img',{attrs:{"src":'data:image/jpeg;base64,' + item.imagen,"width":20}})]}},{key:"item.concepto",fn:function(ref){
                var item = ref.item;
return [(item.concepto.substring(0, 7) == 'EXTORNO')?_c('div',[_c('v-icon',{staticClass:"mb-1",attrs:{"color":"red accent-4"}},[_vm._v(" mdi-format-line-spacing ")]),_c('div',{staticClass:"red--text text-decoration-line-through"},[_vm._v(_vm._s(item.concepto)+" ")])],1):_c('div',{staticClass:"text-caption"},[_vm._v(_vm._s(item.concepto))])]}},{key:"item.nombrecaja",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"text-caption"},[(item.nombrecaja.split(' ')[0] == 'CAJA02')?_c('span',{staticClass:"deep-purple pl-1 pr-1 rounded"},[_vm._v(" "+_vm._s(item.nombrecaja)+" ")]):(item.nombrecaja.split(' ')[0] == 'CAJA04')?_c('span',{staticClass:"pink darken-3 pl-1 pr-1 rounded"},[_vm._v(" "+_vm._s(item.nombrecaja)+" ")]):(item.nombrecaja.split(' ')[0] == 'CAJA03')?_c('span',{staticClass:"orange darken-4 pl-1 pr-1 rounded"},[_vm._v(" "+_vm._s(item.nombrecaja)+" ")]):(item.nombrecaja.split(' ')[0] == 'CAJA05')?_c('span',{staticClass:"teal darken-3 pl-1 pr-1 rounded"},[_vm._v(" "+_vm._s(item.nombrecaja)+" ")]):(item.nombrecaja.split(' ')[0] == 'CAJAPRINCIPAL')?_c('span',{staticClass:"blue darken-3 pl-1 pr-1 rounded"},[_vm._v(" "+_vm._s(item.nombrecaja)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.nombrecaja)+" ")])])]}},{key:"item.acciones",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","x-small":"","color":"primary","disabled":_vm.disVerItem},on:{"click":function($event){return _vm.verItem(item, 'principal')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-history ")])],1)]}},{key:"item.notas",fn:function(ref){
                var item = ref.item;
return [(item.notas != '')?_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-message-text-outline ")]):_vm._e()]}}],null,true)})],1),_c('v-col',{attrs:{"xs":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('div',{staticClass:"d-inline-flex "},[_c('h4',{staticClass:"mb-4"},[_c('v-icon',{staticClass:"mb-1",attrs:{"color":"blue accent-4"}},[_vm._v(" mdi-format-line-spacing ")]),_vm._v(" Gastos en todas las cajas ")],1)]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headerMovimientos,"items":_vm.movimientosSalida,"mobile-breakpoint":"0","item-key":"orden","height":"55vh","fixed-header":"","hide-default-footer":true,"dark":"","header-props":_vm.headerProps,"disable-pagination":"","no-data-text":"No hay registros","loading":_vm.cargaData,"loading-text":"Cargando datos... Por favor espere"},scopedSlots:_vm._u([{key:"item.imagen",fn:function(ref){
                var item = ref.item;
return [_c('v-img',{attrs:{"src":'data:image/jpeg;base64,' + item.imagen,"width":20}})]}},{key:"item.concepto",fn:function(ref){
                var item = ref.item;
return [(item.concepto.substring(0, 7) == 'EXTORNO')?_c('div',[_c('v-icon',{staticClass:"mb-1",attrs:{"color":"red accent-4"}},[_vm._v(" mdi-close-octagon ")]),_c('div',{staticClass:"red--text text-decoration-line-through"},[_vm._v(_vm._s(item.concepto)+" ")])],1):_c('div',{staticClass:"text-caption"},[_vm._v(_vm._s(item.concepto))])]}},{key:"item.nombrecaja",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"text-caption"},[(item.nombrecaja.split(' ')[0] == 'CAJA02')?_c('span',{staticClass:"deep-purple pl-1 pr-1 rounded"},[_vm._v(" "+_vm._s(item.nombrecaja)+" ")]):(item.nombrecaja.split(' ')[0] == 'CAJA04')?_c('span',{staticClass:"pink darken-3 pl-1 pr-1 rounded"},[_vm._v(" "+_vm._s(item.nombrecaja)+" ")]):(item.nombrecaja.split(' ')[0] == 'CAJA03')?_c('span',{staticClass:"orange darken-4 pl-1 pr-1 rounded"},[_vm._v(" "+_vm._s(item.nombrecaja)+" ")]):(item.nombrecaja.split(' ')[0] == 'CAJA05')?_c('span',{staticClass:"teal darken-3 pl-1 pr-1 rounded"},[_vm._v(" "+_vm._s(item.nombrecaja)+" ")]):(item.nombrecaja.split(' ')[0] == 'CAJAPRINCIPAL')?_c('span',{staticClass:"blue darken-3 pl-1 pr-1 rounded"},[_vm._v(" "+_vm._s(item.nombrecaja)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.nombrecaja)+" ")])])]}},{key:"item.acciones",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","x-small":"","color":"primary","disabled":_vm.disVerItem},on:{"click":function($event){return _vm.verItem(item, 'secundario')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-history ")])],1)]}},{key:"item.notas",fn:function(ref){
                var item = ref.item;
return [(item.notas != '')?_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-message-text-outline ")]):_vm._e()]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }