import { direccion } from '../_extra/configuracion'

const state = {
    all: {},
    movimientos: [],
    saldos: [],
    idmovimiento: 0,
    resultado: -1,
    movs: [],
    compras:[],
    actualizarRes: "-1",
    notas: [],
    cuentasCobrarPagar: []
}

const actions = {
    async listarMovimientos({commit}, datos) {
        const opciones = {
            method: 'GET'
        }
        try {
            let response = await fetch(`${direccion().url}/movimientos.php?factor=fa10&fecha=${datos.fecha}&idcaja=${datos.idcaja}`, opciones)
            const contenido = await response.json()
            commit('obtenerTodoCategoria', contenido)
        } catch (error) {
            //console.error(error)
        }
    },
    async listarUtilidades({commit}, datos) {
        const opciones = {
            method: 'GET'
        }
        try {
            let response = await fetch(`${direccion().url}/movimientos.php?factor=fa16&fecha=${datos.fecha}&idcaja=${datos.idcaja}`, opciones)
            const contenido = await response.json()
            commit('obtenerTodoUtilidades', contenido)
        } catch (error) {
            //console.error(error)
        }
    },
    async listarComprasSaldo({commit}, datos) {
        const opciones = {
            method: 'GET'
        }
        try {
            let response = await fetch(`${direccion().url}/movimientos.php?factor=fa18&idcaja=${datos.idcaja}`, opciones)
            const contenido = await response.json()
            commit('obtenerComprasSaldo', contenido)
        } catch (error) {
            //console.error(error)
        }
    },
    async agregarie({commit}, nuevoMovimiento) {
        const opciones = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(nuevoMovimiento)
        }
        try {
            let response = await fetch(`${direccion().url}/movimientos/agregarie`, opciones)
            const contenido = await response.json()
            commit('agregarMovimientoIE', contenido)
        } catch (error) {
            //console.error(error)
        }
    },
    async agregarmovimientocuenta({commit}, nuevoMovimiento) {
        const opciones = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(nuevoMovimiento)
        }
        try {
            let response = await fetch(`${direccion().url}/movimientos/agregarmovimientocuenta`, opciones)
            const contenido = await response.json()
            commit('agregarMovimientoCuenta', contenido)
        } catch (error) {
            //console.error(error)
        }
    },
    async agregaraperturacierre({commit}, nuevoMovimiento) {
        const opciones = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(nuevoMovimiento)
        }
        try {
            let response = await fetch(`${direccion().url}/movimientos.php?accion=a03`, opciones)
            const contenido = await response.json()
            //console.log(contenido)
            commit('agregarMovimientoAperturaCierre', contenido)
        } catch (error) {
            //console.error(error)
        }
    },
    async listarMovimientosCaja({commit}, idCaja) {
        const opciones = {
            method: 'GET',
        }
        try {
            let response = await fetch(`${direccion().url}/movimientos.php?factor=fa03&idcaja=${idCaja}`, opciones)
            const contenido = await response.json()
            
            commit('obtenerListarMovimientos', contenido[0])
        } catch (error) {
            //console.error(error)
        }
    },
    async listarSaldos({commit}, idCaja) {
        const opciones = {
            method: 'GET',
        }
        try {
            let response = await fetch(`${direccion().url}/movimientos.php?factor=fa09&idcaja=${idCaja}`, opciones)
            const contenido = await response.json()
            commit('obtenerListarSaldos', contenido)
        } catch (error) {
            //console.error(error)
        }
    },
    async listarSaldosFecha({commit}, datos) {
        const opciones = {
            method: 'GET',
        }
        try {
            let response = await fetch(`${direccion().url}/movimientos.php?factor=fa14&idcaja=${datos.idcaja}&fecha=${datos.fecha}`, opciones)
            const contenido = await response.json()
            commit('obtenerListarSaldos', contenido)
        } catch (error) {
            //console.error(error)
        }
    },
    async mostrarMovimientoId({commit}, idMovimiento) {
        const opciones = {
            method: 'GET',
        }
        try {
            let response = await fetch(`${direccion().url}/movimientos/mostrarmovimientoid/${idMovimiento}`, opciones)
            const contenido = await response.json()
            commit('obtenerMonstrarMovimientoId', contenido)
        } catch (error) {
            //console.error(error)
        }
    },
    async editar({commit}, categoriaEditar) {
        const opciones = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(categoriaEditar)
        }
        try {
            await fetch(`${direccion().url}/categorias/editar`, opciones)
            commit('editarCategoria', categoriaEditar);
        } catch (error) {
            //console.error(error)
        }
    },
    async eliminar({commit}, idCategoria) {
        const opciones = {
            method: 'GET'
        }
        try {
            let response = await fetch(`${direccion().url}/categorias/eliminar/${idCategoria}`, opciones)
            commit('eliminarCategoria', response) 
        } catch (error) {
            //console.error(error)
        }
    },
    cambiarIdMovmiento({commit}, id) {
        commit('obtenerCambiarIdMovimiento', id)
    },
    async listarTransferenciasCaja({commit}, datos) {
        const opciones = {
            method: 'GET'
        }
        try {
            let response = await fetch(`${direccion().url}/movimientos.php?factor=fa25&fecha=${datos.fecha}&idusuario=${datos.idusuario}&tipo=${datos.tipo}&categoria=${datos.categoria}&texto=${datos.texto}`, opciones)
            const contenido = await response.json()
            commit('obtenerTransferenciasCaja', contenido)
        } catch (error) {
            //console.error(error)
        }
    },
    async actualizarEstadoMovimientoTransferencia({commit}, nuevoMovimiento) {
        const opciones = {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(nuevoMovimiento)
        }
        try {
            //console.log(nuevoMovimiento)
            let response = await fetch(`${direccion().url}/movimientos.php?accion=e08`, opciones)
            const contenido = await response.json()
            //console.log(contenido)
            commit('editarEstadoMovimientoTransferencia', contenido)
        } catch (error) {
            //console.error(error)
        }
    },
    async listarNotas({commit}, datos) {
        const opciones = {
            method: 'GET'
        }
        try {
            let response = await fetch(`${direccion().url}/movimientos.php?factor=fa26&idmovimiento=${datos.idmovimiento}`, opciones)
            const contenido = await response.json()
            
            commit('obtenerNotas', contenido)
        } catch (error) {
           // console.error(error)
        }
    },
    async actualizarNotas({commit}, nuevoMovimiento) {
        const opciones = {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(nuevoMovimiento)
        }
        try {
            //console.log(nuevoMovimiento)
            let response = await fetch(`${direccion().url}/movimientos.php?accion=e09`, opciones)
            const contenido = await response.json()
            //console.log(contenido)
            commit('editarNotas', contenido)
        } catch (error) {
            //console.error(error)
        }
    },
    async listarGastosCaja({commit}, datos) {
        const opciones = {
            method: 'GET'
        }
        try {
            let response = await fetch(`${direccion().url}/movimientos.php?factor=fa27&fecha=${datos.fecha}&idcaja=${datos.idcaja}&categoria=${datos.categoria}`, opciones)
            const contenido = await response.json()
            commit('obtenerGastosCaja', contenido)
        } catch (error) {
            //console.error(error)
        }
    },
    async listarCuentasPorCobrarPagar({commit}, datos) {
        const opciones = {
            method: 'GET'
        }
        try {
            let response = await fetch(`${direccion().url}/movimientos.php?factor=fa15&idusuario=${datos.idusuario}&idcaja=${datos.idcaja}&categoria=${datos.categoria}`, opciones)
            const contenido = await response.json()
            commit('obtenerCuentasPorCobrarPagar', contenido)
        } catch (error) {
            //console.error(error)
        }
    },
}

const mutations = {
    obtenerTodoCategoria(state, movimientos) {
        state.all = {items: movimientos}
        state.movimientos = movimientos
    },
    obtenerTodoUtilidades(state, movimientos) {
        state.all = {items: movimientos}
        state.movimientos = movimientos
    },
    obtenerComprasSaldo(state, compras) {
        state.all = {items: compras}
        state.compras = compras
    },
    obtenerCuentasPorCobrarPagar(state, CuentasCobrarPagar) {
        state.all = {items: CuentasCobrarPagar}
        state.cuentasCobrarPagar = CuentasCobrarPagar
    },
    errorObtenerCategoria(state, error) {
        state.all = { error }
    },  
    agregarMovimientoIE(state, movimiento) {
        state.all = {id: movimiento.id}
        state.idmovimiento = movimiento[0].resultado
    },
    agregarMovimientoCuenta(state, movimiento) {
        state.all = {id: movimiento.id}
        state.idmovimiento = movimiento[0].resultado
    },
    agregarMovimientoAperturaCierre(state, movimiento) {
        state.all = {id: movimiento.id}
        state.resultado = movimiento.coderror
    },
    obtenerListarMovimientos(state, movimientos) {
        state.all = {items: movimientos}
        state.movimientos = movimientos
    },
    obtenerListarSaldos(state, saldos) {
        state.all = {items: saldos}
        state.saldos = saldos
    },
    obtenerMonstrarMovimientoId(state, movimiento) {
        state.all = {items: movimiento}
        state.movs = movimiento
    },
    eliminarCategoria(state, idCategoria) {
        state.all = {id: idCategoria}
    },
    editarCategoria(state, editarCategoria) {
        state.all = {item: editarCategoria}
    },
    obtenerCambiarIdMovimiento(state, idmovimiento) {
        state.idmovimiento = idmovimiento
    },
    obtenerTransferenciasCaja(state, movimientos) {
        state.all = {items: movimientos}
        state.movimientos = movimientos
    },
    editarEstadoMovimientoTransferencia(state, respuesta){
        state.actualizarRes = respuesta.coderror
    },
    obtenerNotas(state, notas) {
        state.notas = notas
    },
    editarNotas(state, respuesta){
        state.actualizarRes = respuesta.coderror
    },
    obtenerGastosCaja(state, movimientos) {
        state.all = {items: movimientos}
        state.movimientos = movimientos
    },
}

export const movimiento = {
    namespaced: true,
    state,
    actions,
    mutations
}