<template>
  <v-container style="padding-left:105px;" fluid>
    <div>
      <v-row>
        <v-col
          cols="4"
           v-for="(item, i) in items"
          :key="i"
        >
          <v-skeleton-loader v-if="cargando == false" :loading="cargando" transition="fade-transition" type="card">
          </v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <div v-if="cargando == true">
      <div class="text-h5 mt-2 mb-3">Control de caja</div>
        <v-divider></v-divider>
        <v-row class="" >
          <v-col
              v-for="(item, i) in items"
              :key="i"
              cols="12"
              md="4"
              sm="12"
            >
              <v-card
                :color="item.color"
                dark
              >
                <div class="d-flex flex-no-wrap justify-space-between">
                  <div>
                    <v-card-title
                      class="headline"
                      v-text="item.title"
                    ></v-card-title>

                    <v-card-subtitle  v-text="item.subtitle"></v-card-subtitle>

                    <v-card-actions>
                      <v-btn
                        class="ml-2 mt-1"
                        outlined
                        rounded
                        small
                        :to="item.link"
                      >
                        Mostrar
                      </v-btn>
                    </v-card-actions>
                  </div>

                  <v-avatar
                    class="ma-3"
                    size="125"
                    tile
                  >
                    <v-icon
                    size="60px"
                    color="white"
                    >
                    {{item.icon}}
                    </v-icon>
                  </v-avatar>
                </div>
              </v-card>
            </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
              <v-card
                class=""
                max-width="300"
                tile
              >
                <v-list dense>
                  <v-list-item-title class="text-body-1 pa-5 pb-2 pt-3">Cajas activas</v-list-item-title>
                  <v-list-item
                    v-for="file in cajasFecha"
                    :key="file.nombrecaja"
                  >
                    <v-list-item-avatar>
                      <v-avatar
                        color="teal"
                        size="48"
                      >
                        <span class="white--text text-h5" v-text="file.avatar"></span>
                      </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title v-text="file.nombrecaja"></v-list-item-title>

                      <v-list-item-subtitle v-text="'usuario: ' + file.nombres "></v-list-item-subtitle>
                      <v-list-item-subtitle v-text="'Apertura: '"></v-list-item-subtitle>
                      <v-list-item-subtitle v-if="file.fecha != null" v-text="file.fecha " class="blue--text"></v-list-item-subtitle>
                      <v-list-item-subtitle v-else v-text="file.fecha " class="red--text"></v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn icon>
                        <v-icon  v-if="file.fecha != null" color="green lighten-1">mdi-checkbox-marked-circle-outline</v-icon>
                        <v-icon  v-else color="deep-orange darken-1"> mdi mdi-sleep</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card>
          </v-col>
        </v-row>
    </div>
    
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'

  export default {
    data: () => (
      {
        selectedItem: 1,
        cargando: false,
        items: [
            {
            color: '#1F7087',
            icon: 'mdi-graph-outline',
            title: 'Registro de movimientos',
            subtitle: 'Ingresos, egresos, compras y ventas del día.',
            link: '/movimientos',
            },
            {
            color: '#0A52AF',
            icon: 'mdi-currency-usd',
            title: 'Utilidades',
            subtitle: 'Reporte de utilidades.',
            link: '/utilidades',
            },
            {
            color: '#00906C',
            icon: 'mdi-redo-variant',
            title: 'Transferencias',
            subtitle: 'Reporte de envio y recepcion de dinero entre cajas.',
            link: '/transferencias',
            },
            {
            color: '#8E24AA',
            icon: 'mdi-arrow-up-bold',
            title: 'Gastos varios',
            subtitle: 'Reporte de gastos (solo salidas) de caja.',
            link: '/gastos',
            },
            {
            color: '#D32F2F',
            icon: 'mdi-alert-rhombus',
            title: 'Cuentas por Pagar',
            subtitle: 'Para cuentas sobregiros soles y dolares.',
            link: '/cuentas-por-pagar',
            },
            {
            color: '#43A047',
            icon: 'mdi-alert-circle',
            title: 'Cuentas por cobrar',
            subtitle: 'Para cuentas deposito soles y dolares.',
            link: '/cuentas-por-cobrar',
            }
        ],
      }
    ),
    computed: {
      ...mapState('caja', ['cajas', 'cajasFecha']),
       ...mapState('usuario', ['usuarios'])
    },
    watch: {
      
    },
    created () {
        this.verficarapertura()
    },
    methods: {
      ...mapActions('caja', ['listarCajasActivas', 'listarCajasActivasFecha', 'listarCajaUsuarios']),
      async verficarapertura(){
        if(this.usuarios.cargo === "administrador"){
          await this.listarCajasActivas()
          await this.listarCajasActivasFecha()
        } else{
          await this.listarCajaUsuarios(this.usuarios.id)
        }
        this.cargando = true
        
      }
    }
  }
</script>
<style>
.v-card__text, .v-card__title {
  word-break: normal; 
}
</style>