<template>
    <div>
        <v-container style="padding-left:70px;" fluid>
            <div class="text-h6 mt-5 mb-3">Utilidades generadas</div>
            <v-row>
                <v-col cols="12" md="6">
                    
                    <div class="d-inline-flex">
                        <v-dialog
                            ref="dialog"
                            v-model="modalfecha"
                            :return-value.sync="date"
                            :first-day-of-week="0"
                            persistent
                            width="290px"
                            
                        >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="computedDateFormatted"
                            label="Fecha de consulta"
                            prepend-icon="mdi-calendar"
                            persistent-hint
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            class="mr-2"
                        ></v-text-field>
                        </template>
                        <v-date-picker
                        v-model="date"
                        scrollable
                        :first-day-of-week="1"
                        locale="es-es"

                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="modalfecha = false"
                        >
                            Cancelar
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(date); fechadatos();"
                        >
                            Aplicar
                        </v-btn>
                        </v-date-picker>
                        
                    </v-dialog>
                    <v-select class="mt-3 pl-5"
                        v-model="cajapordefecto"
                        :items="cajas"
                        :item-text="'nombrecaja'"
                        :item-value="'id'"
                        label="Seleccione Caja"
                        dense
                        outlined
                        style="max-width: 250px; margin-bottom:-30px;"
                        background-color="white"
                        solo
                        light
                        @change="seleccionarCaja"
                        >
                            <template v-slot:append-outer>
                                <v-btn
                                class="mt-0"
                                small
                                fab
                                dark
                                color="orange"
                                style="top: -8px;"
                                @click="actualizarDatos()"
                                >
                                    <v-icon dark>
                                        mdi-refresh
                                    </v-icon>
                                </v-btn>
                                </template>
                        </v-select>
                        
                    </div>
                </v-col>
                <v-col cols="12" md="6" class="mt-md-3 mt-0 pt-0 pt-md-3">
                    <v-btn color="primary" @click="toggleAll()">Detalles</v-btn>
                        <v-btn class="ml-2" color="info" @click="ocultar()">Ocultar</v-btn>
                </v-col>
                <v-col cols="12">
                    <v-data-table
                    :headers="headerMovimientos"
                    :items="movimientos"
                    mobile-breakpoint="0"
                    item-key="id"
                    group-by="idpromoventa"
                    class="elevation-1"
                    fixed-header
                    height="55vh"
                    :hide-default-footer="true"
                    dark
                    :header-props="headerProps"
                    disable-pagination
                     no-data-text="No hay registros"
                    :loading="cargaData"
                    loading-text="Cargando datos... Por favor espere"
                    ref="table"
                    >
                    <template v-slot:[`group.header`]="{ items, group, headers, toggle, isOpen }">
                        <td :colspan="headers.length">
                            <v-row align="center">
                                <v-btn @click="toggle" small icon :ref="group" :data-open="isOpen" class="text-center">
                                    <v-icon v-if="isOpen">mdi-chevron-up</v-icon>
                                    <v-icon v-else>mdi-chevron-down</v-icon>
                                </v-btn>
                                <v-col cols="1" md="1" class="hidden-xs-only  text-center  ">
                                    Venta
                                </v-col>
                                <v-col cols="2" md="1" class="text-end">
                                    {{ items[0].venta }}
                                </v-col>
                                <v-col cols="2" md="1" class="text-end">
                                    {{ parseFloat(items[0].tcventa).toString()}}
                                </v-col>
                                <v-col cols="2" md="1" class=" text-end green--text text--accent-3 font-weight-black text-subtitle-1">
                                    {{ items[0].totalutilidad}}
                                </v-col>
                                <v-col cols="2"  class=" hidden-xs-only text-center ">
                                   {{ items[0].fecha}}
                                </v-col>
                                <v-col cols="1" class="">
                                    PV-{{ items[0].idpromoventa}}
                                </v-col>
                                <v-col cols="1" class="text-center">
                                    {{items[0].nombremoneda}}
                                    <v-img :src="'data:image/jpeg;base64,' + items[0].imagen" :width="20" class="text-center ml-11"></v-img>
                                </v-col>
                                <v-col cols="2" class="text-center">
                                    
                                </v-col>
                            </v-row>
                        </td>
                    </template>
                    <template v-slot:item="{ item }">
                        <tr>
                        <td>Compra</td>
                        <td class="text-end"><strong>{{ item.importe }}</strong></td>
                        <td class="text-end">{{parseFloat(item.tccompra).toString()}}</td>
                        <td class="text-end green--text text--accent-3">{{item.utilidad}}</td>
                        <td>|</td>
                        <td class="text-center">{{item.fecha}}</td>
                        <td class="text-center">PC-{{item.idpromocompra}}</td>
                        <td class="text-end">{{item.compra}}</td>
                        <td class="text-center">{{item.nombremoneda}}</td>
                        <td class="text-center"><v-img :src="'data:image/jpeg;base64,' + item.imagen" :width="20" class="text-center"></v-img></td>
                        </tr>
                    </template>
                    </v-data-table>
                </v-col>
                <v-col cols="12" md="4" offset-md="4">
                    <v-card dark>
                        <v-card-text>
                            <v-row align="center">
                                <v-col
                                class="text-h5"
                                cols="6"
                                >
                                    Total utilidad:
                                </v-col>
                                <v-col cols="6" class="text-h5 text-center green--text text--accent-3">
                                    {{sumautilidad}}
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" class="mb-5">
                    <div class="text-h6 mt-3 mb-3"> Compras con saldo para utilizar</div>
                    <v-data-table
                        :headers="headerCompras"
                        :items="compras"
                        mobile-breakpoint="0"
                        item-key="orden"
                        class="elevation-1"
                        height="40vh"
                        fixed-header
                        :hide-default-footer="true"
                        dark
                        :header-props="headerProps"
                        disable-pagination
                        no-data-text="No hay registros"
                        :loading="cargaData"
                        loading-text="Cargando datos... Por favor espere"
                    >
                        <template v-slot:[`item.imagen`]="{ item }" >
                            <div align="center">
                                <v-img :src="'data:image/jpeg;base64,' + item.imagen" :width="20" ></v-img>
                            </div>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>

import {mapState, mapActions} from 'vuex'

export default {
    name: 'Movimientos',
    components: {
    },
    data: () => (
        {
            sumautilidad: 0,
            sumaSaldos: 0, 
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            cargaData: false,
            modalfecha: false,
            headerMovimientos: [
                { text: '#', filterable: false, sortable: false, value: 'concepto'},
                { text: 'Concepto', filterable: false, sortable: false, value: 'concepto'},
                { text: 'Compra', value: 'importe', align: 'center' },
                { text: 'Tipo cambio C', value: 'tccompra', align: 'start' },
                { text: 'Utilidad', value: 'utilidad', align: 'start' },
                { text: 'Fecha', value: 'fecha', align: 'center ' },
                { text: 'Comprobante', value: 'comprobante', align: 'right' },
                { text: 'Compra original', value: 'compra', align: 'right' },
                { text: 'Moneda', value: 'nombremoneda', align: 'right' },
                { text: 'I', value: 'nombremoneda', align: 'right' },
            ],
            headerCompras: [
                { text: 'Nro', sortable: true, value: 'id'},
                { text: 'Importe', sortable: true, value: 'importe', align: 'end'},
                { text: 'Tipo cambio', sortable: true, value: 'tipocambio' , align: 'end'},
                { text: 'Fecha', sortable: true, value: 'fecha' , align: 'center'},
                { text: 'Saldo para usar', sortable: true, value: 'saldoutilidad' , align: 'end'},
                { text: 'Im', sortable: true,  value: 'imagen', align: 'center' },
                { text: 'Moneda', sortable: true, value: 'nombremoneda' },
            ],
            editandoItem: {
                nombrecaja: ''
            },
            inicioItem: {
                nombrecaja: ''
            },
            cajapordefecto: 1,
            headerProps: {
                sortByText: "Ordenar por ..."
            },
        }
    ),
    async created() {
        if(this.usuarios.cargo === "administrador"){
            await this.listarCajasActivas()
        } else{
            await this.listarCajasPorUsuario(this.usuarios.id)
        }
        this.cajapordefecto = this.cajas[0].id
        await this.cargarMovmientos()
    },
    computed: {
        ...mapState('usuario', ['usuarios']),
        ...mapState('movimiento', ['movimientos', 'saldos', 'compras']),
        ...mapState('caja', ['cajas', 'idseleccion']),
        computedDateFormatted () {
            return this.formatDate(this.date)
        },
    },
    methods: {
        ...mapActions('movimiento', ['listarMovimientos', 'listarSaldosFecha','listarUtilidades', 'listarComprasSaldo']),
        ...mapActions('caja', ['listarCajasPorUsuario','actualizarIdCaja', 'listarCajasActivas']),
        async cargarMovmientos() {
            this.cargaData = true
            var datos = {
                fecha: this.date,
                idcaja: this.cajapordefecto
            }
            await this.listarUtilidades(datos);            
            await this.listarComprasSaldo(datos);
            this.cargaData = false
            this.totalutilidad()
            this.totalSaldoCompras()
            this.ocultar ()
            
        },
       verMovimiento(item){
           //console.log(item.accion)
           this.cambiarIdMovmiento(item.codigo.substring(3))
           if(item.accion == 'entrada') {
               this.$refs.customModalmov.mostrarMov()
                this.dialogIE = true
               
           } else if (item.accion == 'deposito') {
                this.$refs.customModalDeposito.mostrarMov()
                this.dialogIE = true
           } else {
                console.log("hola")
           }
       },
       async seleccionarCaja(item){
         //console.log(item)
          this.actualizarIdCaja(item)
          await this.cargarMovmientos()
          
          //console.log(this.cajas)
          
        },
        async actualizarDatos()
        {
             await this.cargarMovmientos()
        },
        totalutilidad()
        {
            let suma = 0
            for (var mov in this.movimientos) {
                suma = suma + parseFloat(this.movimientos[mov].utilidad)
            }
            this.sumautilidad = suma.toFixed(2)
        },
        totalSaldoCompras(){
            let suma = 0
            for (var mov in this.compras) {
                suma = suma + parseFloat(this.compras[mov].saldoutilidad)
            }
            this.sumaSaldos = suma.toFixed(2)
        },
        numeroComas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
        parseDate (date) {
            if (!date) return null

            const [month, day, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        async fechadatos()
        {
            await this.actualizarDatos()
        },
        getColor (accion) {
            if (accion == 'DE') return 'blue'
            else if (accion == 'PR') return 'orange'
            else if (accion == 'RE') return 'red'
            else return 'green'
        },
        toggleAll () {
            /*if(this.movimientos.length > 0)
            {
                Object.keys(this.$refs).forEach(k => {
                    console.log(this.$refs[k])
                    this.$refs[k].$el.click()
                })
            }*/
            let table = this.$refs.table;
            let keys = Object.keys(table.$vnode.componentInstance.openCache);
            keys.forEach(x => {
                table.$vnode.componentInstance.openCache[x] = true;
            })
        },
        ocultar () {
        /*if(this.movimientos.length > 0)
        {
            Object.keys(this.$refs).forEach(k => {
                console.log(this.$refs[k])
                this.$refs[k].$el.click()
            })
        }*/
        let table = this.$refs.table;
        let keys = Object.keys(table.$vnode.componentInstance.openCache);
        keys.forEach(x => {
            table.$vnode.componentInstance.openCache[x] = false;
        })
        }
    },
    watch: {
        date () {
            this.dateFormatted = this.formatDate(this.date)
        },
    }
}
</script>
<style>
.abajo-items > .v-data-table__wrapper{
      overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
}
</style>