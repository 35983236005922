var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","ma-0":"","pa-0":"","fill-heigh":""}},[_c('v-card',{attrs:{"color":"grey lighten-4","flat":"","height":"50px","tile":""}},[_c('v-app-bar',{attrs:{"color":"orange darken-3","dense":"","dark":"","fixed":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.estadoBar($event)}}}),_c('v-toolbar-title',[_vm._v("Cambios en linea")]),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{attrs:{"color":"green","content":"1","overlap":"","bottom":""}},[_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-bell ")])],1)],1)]}}])},[_c('v-list',[_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',{staticClass:"blue lighten-1 white--text"},[_vm._v(" mdi-currency-usd ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Reporte de cuentas por pagar")]),_c('v-list-item-subtitle',[_vm._v("29/03/2024")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","href":"/cuentas-por-pagar"}},[_c('v-icon',{attrs:{"color":"gree lighten-1"}},[_vm._v(" mdi-exit-to-app ")])],1)],1)],1),_c('v-list-item',[_c('v-list-item-avatar',[_c('v-icon',{staticClass:"blue lighten-1 white--text"},[_vm._v(" mdi-currency-usd ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Reporte de cuentas por cobrar")]),_c('v-list-item-subtitle',[_vm._v("29/03/2024")])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","href":"/cuentas-por-cobrar"}},[_c('v-icon',{attrs:{"color":"gree lighten-1"}},[_vm._v(" mdi-exit-to-app ")])],1)],1)],1)],1)],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.cerrarSesion}},[_c('v-list-item-title',[_vm._v("Cerrar Sesión")])],1)],1)],1)],1)],1),_c('v-navigation-drawer',{staticClass:"mt-5",staticStyle:{"top":"30px"},attrs:{"color":_vm.color,"mini-variant":_vm.miniVariant,"right":_vm.right,"src":_vm.bg,"clipped":"","permanent":!_vm.permanent,"fixed":"","app":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{staticClass:"py-0",attrs:{"dense":"","nav":""}},[_c('v-list-item',{class:_vm.miniVariant && 'px-0',attrs:{"two-line":""}},[_c('v-list-item-avatar',[_c('v-icon',[_vm._v("mdi-face")])],1),(_vm.estado.logueado)?_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.cemail))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.cnombres)+" "+_vm._s(_vm.capellidos)+" ")]),_c('v-list-item-subtitle',{staticClass:"green--text font-weight-medium"},[_vm._v(_vm._s(_vm.ccargo))])],1):_vm._e()],1),_c('v-divider'),_vm._l((_vm.items),function(item){return _c('v-list-item',{key:item.title,attrs:{"link":"","to":item.ruta}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"mr-2"},'div',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":item.color}},[_vm._v(" "+_vm._s(item.icon)+" ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title))])],1)],1)})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }