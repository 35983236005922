<template >
  <div>
    <v-container style="padding-left: 70px"  fluid >
      <v-row>
        <v-col cols="12" class="mb-0 pb-0 d-flex">
          <div class="text-h6 mt-5 mb-3">Cuentas por Pagar</div>
          <v-spacer></v-spacer>
          <v-icon left large color="red darken-1 me-2">
            mdi-alert-rhombus
          </v-icon>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn depressed color="primary" @click="crearListadoMovimientos()">
            Generar
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        class="fill-height"
        align-content="center"
        justify="center"
        v-if="cargarReporte"
      >
        <v-col class="text-subtitle-1 text-center dark" cols="12">
          Cargando las cuentas...
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="primary"
            rounded
            height="6"
            :active="cargarReporte"
            :indeterminate="cargarReporte"
          ></v-progress-linear>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="(item, i) in listaMovCuenta"
          :key="i"
          cols="12"
          sm="6"
          md="4"
        >
          <v-card>
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <div class="text-overline ps-4 pt-3 pb-0">
                  {{ item[0].codigo }}
                </div>
                <v-card-title
                  class="text-body-1 py-2 pt-0"
                  :class="desenfocarText"
                  v-text="item[0].nombres"
                ></v-card-title>
                <div class="py-0 px-4 grey--text d-flex">
                  <v-icon left small color="grey darken-5 me-2">
                    mdi-clock-outline
                  </v-icon>
                  Ultimo registro
                </div>

                <v-card-subtitle
                  v-text="item[item.length - 1].fecha"
                  class="py-0 pt-0"
                ></v-card-subtitle>
              </div>
              <div
                class="d-flex flex-column pe-5 pt-5"
                style="align-items: end"
              >
                <div>
                  <v-img
                    :src="'data:image/jpeg;base64,' + item[0].imagen"
                    :width="20"
                    class="d-flex justify-end"
                  ></v-img>
                </div>
                <div
                  class="mt-5 d-flex flex-column align-center"
                  style="align-items: center !important"
                >
                  <h2 class="d-flex justify-end" :class="desenfocarText">
                    {{ item[item.length - 1].saldo }}
                  </h2>
                </div>
              </div>
            </div>
            <v-spacer></v-spacer>
            <v-card-actions>
              <v-btn
                class="ma-2 mt-3 mb-2 px-4 py-4 white--text"
                small
                color="primary"
                @click="verItem(item)"
              >
                Ver movimientos
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <div>
        <v-dialog v-model="dialVer" max-width="820px">
          <v-card class="mx-auto">
            <v-card-title class="text-subtitle-1"
              >Ultimos 5 movimientos

              <v-spacer></v-spacer>
              <div class="green--text">{{ dialNombre }}</div>
            </v-card-title>
            <v-card-text>
              <v-data-table
                :headers="headerNotas"
                :items="notas"
                :items-per-page="5"
                class="elevation-1"
                mobile-breakpoint="0"
                no-data-text="No hay registros"
                :loading="cargaDataNotas"
                loading-text="Cargando datos... Por favor espere"
                :footer-props="{
                  'items-per-page-text': 'Registros por pagina',
                  'items-per-page-all-text': 'Todos',
                }"
                :hide-default-footer="true"
              >
                <template v-slot:[`item.imagen`]="{ item }">
                  <v-img
                    :src="'data:image/jpeg;base64,' + item.imagen"
                    :width="20"
                  ></v-img>
                </template>
                <template v-slot:[`item.saldo`]="{ item }">
                  <div class="font-weight-black">{{ item.saldo }}</div>
                </template>
                <template v-slot:[`footer.page-text`]="items">
                  {{ items.pageStart }} - {{ items.pageStop }} de
                  {{ items.itemsLength }}
                </template>
                <template v-slot:[`item.estado`]="{ item }">
                  <v-chip
                    :color="
                      item.estado == 'PENDIENTE'
                        ? '#FF6F00'
                        : item.estado == 'CONFIRMADO'
                        ? '#00C853'
                        : '#D50000'
                    "
                    v-text="item.estado"
                    class="white--text"
                  ></v-chip>
                </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions class="pb-4">
              <div class="d-flex flex-column align-center">
                <div class="mx-5 d-flex">
                  Saldo Total
                  <v-img
                    :src="'data:image/jpeg;base64,' + monedaText"
                    :width="20"
                    class="ms-2"
                  ></v-img>
                </div>

                <div class="text-body-1 font-weight-black d-flex">
                  {{ saldoText }}
                </div>
              </div>
              <v-spacer></v-spacer>

              <v-btn color="blue darken-1 " text @click="cerrarVer"
                >Cerrar</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Cuentas-por-cobrar",
  components: {},
  data: () => ({
    cargarReporte: false,
    dialVer: false,
    disVerItem: false,
    snackbar: false,
    textsnackbar: "",
    notas: [],
    cargaDataNotas: false,
    dialNombre: "",
    fechaUltimoMov: "",
    desenfocarText: "",
    saldoText: "",
    monedaText: "",
    headerNotas: [
      { text: "Debe", sortable: false, value: "debe", align: "end" },
      { text: "Haber", sortable: false, value: "haber", align: "end" },
      { text: "Saldo", sortable: false, value: "saldo", align: "end" },
      { text: "Fecha", sortable: false, value: "fecha", align: "center" },
      { text: "Numero", sortable: false, value: "numero" },
      { text: "Concepto", sortable: false, value: "concepto" },
      {
        text: "Moneda",
        sortable: false,
        value: "nombremoneda",
        align: "center",
      },
      { text: "I", sortable: false, value: "imagen", align: "center" },
    ],
    margentext: 0,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    listaMovCuenta: {},
  }),
  async created() {
    const margenes = JSON.parse(localStorage.getItem("datos"));

    if (!margenes) {
      const datos = {
        margen: 0.05,
      };
      localStorage.setItem("datos", JSON.stringify(datos));
    } else {
      this.margentext = margenes.margen;
    }

    await this.listarCajasPorUsuario(this.usuarios.id);

    this.cajapordefecto = this.cajas[0].idcaja;

    this.cargaData = true;

    await this.crearListadoMovimientos();
  },
  computed: {
    ...mapState("usuario", ["usuarios"]),
    ...mapState("movimiento", ["movimientos", "saldos", "cuentasCobrarPagar"]),
    ...mapState("caja", ["cajas", "idseleccion"]),
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  methods: {
    ...mapActions("movimiento", [
      "listarMovimientos",
      "listarSaldosFecha",
      "listarComprasSaldo",
      "listarCuentasPorCobrarPagar",
    ]),
    ...mapActions("caja", [
      "listarCajasActivas",
      "actualizarIdCaja",
      "listarCajasPorUsuario",
    ]),
    async crearListadoMovimientos() {
      this.cargarReporte = true;
      let datos = {
        categoria: 2,
        idcaja: this.cajapordefecto,
        idusuario: this.usuarios.id,
      };
      await this.listarCuentasPorCobrarPagar(datos);
      let obj = {};
      let cont = 0;
      this.cuentasCobrarPagar.forEach(function (v) {
        let cuenta = v.idcuenta;

        let arrPivot = {};
        //console.log(!!Object.getOwnPropertyDescriptor(obj, cuenta))
        if (!Object.getOwnPropertyDescriptor(obj, cuenta)) {
          obj[cuenta] = [];
          cont = 0;
        }
        arrPivot = v;
        delete arrPivot.idcuenta;
        if (cont < 5) {
          obj[cuenta].unshift(arrPivot);
        }
        cont++;
      });
      this.listaMovCuenta = obj;
      this.cargarReporte = false;
    },
    async verItem(item) {
      this.desenfocarText = "overlay";
      this.disVerItem = true;
      this.dialVer = true;
      this.dialNombre = item[0].nombres;
      this.fechaUltimoMov = item[item.length - 1].fecha;
      this.saldoText = item[item.length - 1].saldo;
      this.monedaText = item[0].imagen;
      this.notas = item;
      this.disVerItem = false;
    },
    async cerrarVer() {
      this.desenfocarText = "";
      this.dialVer = false;
    },
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
    dialVer: function (newValue) {
      if (!newValue) {
        this.desenfocarText = "";
      }
    },
  },
};
</script>
<style>
.abajo-items > .v-data-table__wrapper {
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
}
.overlay {
  filter: blur(15px);
}
</style>

        