import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Movmientos from '../views/Movimientos.vue'
import Utilidades from '../views/Utilidades.vue'
import Transferencias from '../views/Transferencias.vue'
import Gastos from '../views/Gastos.vue'
import CuentasCobrar from '../views/Cuentas-por-cobrar.vue'
import CuentasPagar from '../views/Cuentas-por-pagar.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login
    //component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/movimientos',
    name: 'Movimientos',
    component: Movmientos
    //component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/utilidades',
    name: 'Utilidades',
    component: Utilidades
    //component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/transferencias',
    name: 'Transferencias',
    component: Transferencias
    //component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/gastos',
    name: 'Gastos',
    component: Gastos
    //component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/cuentas-por-pagar',
    name: 'Cuentas-por-pagar',
    component: CuentasPagar
    //component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/cuentas-por-cobrar',
    name: 'Cuentas-por-cobrar',
    component: CuentasCobrar
    //component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const logueado = localStorage.getItem('usuario');
  //console.log(">>: " + !logueado + " >>: " + authRequired)
  if (!logueado && authRequired) {
    //console.log('aqui 1: ' + to.fullPath)
      next({
        path: '/login'
      })
  }else {
    next()
  }
})

export default router
