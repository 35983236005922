import { direccion } from '../_extra/configuracion'

const state = {
    all: {},
    monedas: [],
    idseleccion: -1,
    nombreseleccion: ''
}

const actions = {
    async listarMonedas({commit}) {
        const opciones = {
            method: 'GET'
        }
        try {
            let response = await fetch(`${direccion().url}/monedas.php?factor=fa02`, opciones)
           //console.log(response)
            const contenido = await response.json()
            commit('obtenerMonedas', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async listarMonedasConcatenado({commit}) {
        const opciones = {
            method: 'GET'
        }
        try {
            let response = await fetch(`${direccion().url}/monedas.php?factor=fa01`, opciones)
            const contenido = await response.json()
            commit('obtenerMonedasConcatenado', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    actualizarIdMoneda({commit}, valor){
        //console.log(valor)
        commit('modificarIdMoneda', valor)
    }
}

const mutations = {
    obtenerMonedas(state, contenido) {
        state.all = {resultado : contenido}
        state.monedas = contenido
    },
    obtenerMonedasConcatenado(state, contenido) {
        state.all = {resultado : contenido}
        state.monedas = contenido
    },
    modificarIdMoneda(state, valor){
        state.idseleccion = valor.id
        state.nombreseleccion = valor.nombremoneda
        //console.log(state.nombreseleccion)
    }
}

export const moneda = {
    namespaced: true,
    state,
    actions,
    mutations
}