<template>
  <div>
    <v-container style="padding-left: 70px" fluid>
      <v-row>
        <v-col md="12">
          <div class="text-h6 mt-5 mb-3">Lista de Gastos en las caja activas</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6" sm="6" md="3" lg="2" xl="2" class="pb-0">
          <v-dialog
            ref="dialog"
            v-model="modalfecha"
            :return-value.sync="date"
            :first-day-of-week="0"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
              
                v-model="computedDateFormatted"
                label="Fecha de consulta"
                prepend-icon="mdi-calendar"
                persistent-hint
                readonly
                v-bind="attrs"
                v-on="on"
                class="mr-2 mt-0"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              scrollable
              :first-day-of-week="1"
              locale="es-es"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modalfecha = false">
                Cancelar
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.dialog.save(date);
                  fechadatos();
                "
              >
                Aplicar
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="6" sm="6" md="3" lg="2" xl="2" class="pb-0">
            <v-select
              class="mt-0 pl-0 mr-0 " 
              v-model="cajapordefecto1"
              :items="cajas"
              :item-text="'nombrecaja'"
              :item-value="'id'"
              label="Seleccione Caja"
              dense
              outlined
              style="max-width: 200px; margin-bottom: -30px"
              background-color="white"
              solo
              light
              @change="seleccionarCaja1"
            >
            </v-select>
        </v-col>
        <v-col cols="2" sm="4" md="1" lg="1" xl="1" class="pl-1 pr-0 pb-0">
            <v-btn
              class="ml-0"
              small
              fab
              dark
              color="orange"
              @click="actualizarDatos()"
            >
              <v-icon dark> mdi-refresh </v-icon>
            </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col xs="12" sm="12" md="6" lg="6" xl="6">
            <h4 class="mb-2 ">
              <v-icon
                class="mb-1"
                color="blue accent-4"
              >
                mdi-format-line-spacing
              </v-icon>
              Gastos para la caja seleccionada -> 
              <v-chip
                class="ml-2 mb-0"
                color="primary"
              >
                {{ textodefecto }}
              </v-chip>
            </h4>
            <v-data-table
              :headers="headerMovimientos"
              :items="movimientosEntrada"
              item-key="orden"
              class="elavation-4"
              mobile-breakpoint="0"
              height="55vh"
              fixed-header
              :hide-default-footer="true"
              dark
              :header-props="headerProps"
              disable-pagination
              no-data-text="No hay registros"
              :loading="cargaData"
              loading-text="Cargando datos... Por favor espere"
            >
              <template v-slot:[`item.imagen`]="{ item }">
                <v-img
                  :src="'data:image/jpeg;base64,' + item.imagen"
                  :width="20"
                ></v-img>
              </template>
              <template v-slot:[`item.concepto`]="{ item }">
                <div
                    v-if="item.concepto.substring(0, 7) == 'EXTORNO'"
                >
                  <v-icon
                    class="mb-1"
                    color="red accent-4"
                  >
                    mdi-format-line-spacing
                  </v-icon>
                  <div class="red--text text-decoration-line-through">{{ item.concepto }} </div> 
                </div>
                <div v-else class="text-caption">{{ item.concepto }}</div>
              </template>
              <template v-slot:[`item.nombrecaja`]="{ item }">
                <div class="text-caption">  
                    <span v-if="item.nombrecaja.split(' ')[0] == 'CAJA02'"  class="deep-purple pl-1 pr-1 rounded">
                        
                        {{ item.nombrecaja }}
                    </span>
                    <span v-else-if="item.nombrecaja.split(' ')[0] == 'CAJA04'" class="pink darken-3 pl-1 pr-1 rounded">
                        {{ item.nombrecaja }}
                    </span>
                    <span v-else-if="item.nombrecaja.split(' ')[0] == 'CAJA03'" class="orange darken-4 pl-1 pr-1 rounded">
                        {{ item.nombrecaja }}
                    </span>
                    <span v-else-if="item.nombrecaja.split(' ')[0] == 'CAJA05'" class="teal darken-3 pl-1 pr-1 rounded">
                        {{ item.nombrecaja }}
                    </span>
                    <span v-else-if="item.nombrecaja.split(' ')[0] == 'CAJAPRINCIPAL'" class="blue darken-3 pl-1 pr-1 rounded">
                        {{ item.nombrecaja }}
                    </span>
                    <span v-else>
                       {{ item.nombrecaja }}
                    </span>
                </div>
              </template>
              <template v-slot:[`item.acciones`]="{ item }">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  x-small
                  color="primary"
                  @click="verItem(item, 'principal')"
                  :disabled="disVerItem"
                >
                  <v-icon
                    small
                  >
                    mdi-history
                  </v-icon>
                </v-btn>
              </template>
              <template  v-slot:[`item.notas`]="{ item }" >
                  <v-icon
                    v-if="item.notas != ''"
                    small
                  >
                    mdi-message-text-outline
                  </v-icon>
              </template>
            </v-data-table>
        </v-col>
        <v-col xs="12" sm="12" md="6" lg="6" xl="6">
          <div class="d-inline-flex ">
            <h4 class="mb-4">
              <v-icon
                class="mb-1"
                color="blue accent-4"
              >
                mdi-format-line-spacing
              </v-icon>
              Gastos en todas las cajas
            </h4>
          </div>
          <v-data-table
              :headers="headerMovimientos"
              :items="movimientosSalida"
              mobile-breakpoint="0"
              item-key="orden"
              class="elevation-1"
              height="55vh"
              fixed-header
              :hide-default-footer="true"
              dark
              :header-props="headerProps"
              disable-pagination
              no-data-text="No hay registros"
              :loading="cargaData"
              loading-text="Cargando datos... Por favor espere"
            >
              <template v-slot:[`item.imagen`]="{ item }">
                <v-img
                  :src="'data:image/jpeg;base64,' + item.imagen"
                  :width="20"
                ></v-img>
              </template>
              <template v-slot:[`item.concepto`]="{ item }">
                <div
                    v-if="item.concepto.substring(0, 7) == 'EXTORNO'"
                >
                  <v-icon
                    class="mb-1"
                    color="red accent-4"
                  >
                    mdi-close-octagon
                  </v-icon>
                  <div class="red--text text-decoration-line-through">{{ item.concepto }} </div> 
                </div>
                <div v-else class="text-caption">{{ item.concepto }}</div>
              </template>
              <template v-slot:[`item.nombrecaja`]="{ item }">
                <div class="text-caption">  
                    <span v-if="item.nombrecaja.split(' ')[0] == 'CAJA02'"  class="deep-purple pl-1 pr-1 rounded">
                        
                        {{ item.nombrecaja }}
                    </span>
                    <span v-else-if="item.nombrecaja.split(' ')[0] == 'CAJA04'" class="pink darken-3 pl-1 pr-1 rounded">
                        {{ item.nombrecaja }}
                    </span>
                    <span v-else-if="item.nombrecaja.split(' ')[0] == 'CAJA03'" class="orange darken-4 pl-1 pr-1 rounded">
                        {{ item.nombrecaja }}
                    </span>
                    <span v-else-if="item.nombrecaja.split(' ')[0] == 'CAJA05'" class="teal darken-3 pl-1 pr-1 rounded">
                        {{ item.nombrecaja }}
                    </span>
                    <span v-else-if="item.nombrecaja.split(' ')[0] == 'CAJAPRINCIPAL'" class="blue darken-3 pl-1 pr-1 rounded">
                        {{ item.nombrecaja }}
                    </span>
                    <span v-else>
                       {{ item.nombrecaja }}
                    </span>
                </div>
              </template>
              <template v-slot:[`item.acciones`]="{ item }">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  x-small
                  color="primary"
                  @click="verItem(item, 'secundario')"
                  :disabled="disVerItem"
                >
                  <v-icon
                    small
                  >
                    mdi-history
                  </v-icon>
                </v-btn>
              </template>
              <template  v-slot:[`item.notas`]="{ item }" >
                  <v-icon
                    v-if="item.notas != ''"
                    small
                  >
                    mdi-message-text-outline
                  </v-icon>
              </template>
            </v-data-table>
        </v-col>
      </v-row>
      </v-container>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Movimientos",
  components: {},
  data: () => ({
    carActualizarNotas: false,
    disVerItem: false,
    dialVer: false,
    snackbar: false,
    textsnackbar: '',
    timeout: 3000,
    colorsnackbar: 'succcess',
    movimientosEntrada: [],
    movimientosSalida: [],
    movimientosCajasEntrada: [],
    movimientosCajasSalida: [],
    categoria: true,
    textoCategoria: "Mostrar Todos",
    margentext: 0,
    cargaData: false,
    cargaDataNotas: false,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    modalfecha: false,
    headerMovimientos: [
      { text: "Concepto", sortable: true, value: "concepto", width: 50 },
      { text: "Caja", sortable: true, value: "nombrecaja", width: 50 },
      { text: "Importe", sortable: true, value: "importe", align: "end" },
      {
        text: "Tipo cambio",
        sortable: true,
        value: "tipocambio",
        align: "end",
      },
      { text: "Im", sortable: true, value: "imagen" },
      { text: "Codigo", sortable: true, value: "codigo" },
      { text: "Fecha", sortable: true, value: "fecha" },
      { text: "Moneda", sortable: true, value: "nombremoneda" },
    ],
    headerNotas: [
      
      { text: "Estado", sortable: true, value: "estado" },
      { text: "Fecha", sortable: true, value: "fecha" },
      { text: "Usuario", sortable: true, value: "usuario" },

    ],
    editandoItem: {
      nombrecaja: "",
    },
    inicioItem: {
      nombrecaja: "",
    },
    cajapordefecto: 1,
    cajapordefecto1: 1,
    cajaAnalizar: 1,
    cajaAnalizar2: 1,
    monedadefecto: 1,
    textodefecto: "CAJA1",
    textodefectoAnalizar: "CAJA1",
    headerProps: {
      sortByText: "Ordenar por ...",
    },
    idusuariolocal: 3,
    idusuariolocalAnalizar: 0,
    idmovimientoNotas: 0,
    notasText: '',
    datatableselec: 'principal',
  }),
  async created() {
    const margenes = JSON.parse(localStorage.getItem("datos"));

    if (!margenes) {
      const datos = {
        margen: 0.05,
      };
      localStorage.setItem("datos", JSON.stringify(datos));
    } else {
      this.margentext = margenes.margen;
    }

    var idusuario = this.usuarios.id;
    if (this.idseleccion <= -1) {
      if (this.usuarios.cargo === "administrador") {
        await this.listarCajasActivas();
      } else {
        await this.listarCajasPorUsuario(idusuario);
      }
      //console.log(this.cajas);
      this.cajapordefecto = this.cajas[0].id;
      this.cajapordefecto1 = this.cajas[0].id;
      this.idusuariolocal = this.usuarios.id;
    } else {
      if (this.usuarios.cargo === "administrador") {
        await this.listarCajasActivas()
      } else {
        await this.listarCajasPorUsuario(idusuario)
      }
      
      this.cajapordefecto = this.cajas[0].id
      this.cajapordefecto1 = this.cajas[0].id
    }
    //console.log("cajaa: " + this.cajapordefecto1);
    this.textodefecto =  this.cajas[0].nombrecaja
    await this.cargarMovmientos();
    //await this.listarMonedas()
  },
  computed: {
    ...mapState("usuario", ["usuarios"]),
    ...mapState("movimiento", ["movimientos", "saldos", "actualizarRes","notas"]),
    ...mapState("caja", ["cajas", "idseleccion"]),
    ...mapState("moneda", ["monedas", "idseleccion"]),
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  methods: {
    ...mapActions("movimiento", ["listarTransferenciasCaja","actualizarEstadoMovimientoTransferencia",'listarNotas','actualizarNotas','listarGastosCaja']),
    ...mapActions("caja", [
      "listarCajasActivas",
      "actualizarIdCaja",
      "listarCajasPorUsuario",
    ]),
    ...mapActions("moneda", ["listarMonedas", "actualizarIdMoneda"]),
    async cargarMovmientos() {
      this.cargaData = true;
      var datos = {
        fecha: this.date,
        idcaja: this.cajapordefecto,
        categoria: "individual",
      };

      console.log(datos);
      await this.listarGastosCaja(datos);
      this.movimientosEntrada = this.movimientos;
      if (this.usuarios.cargo === "administrador") {
        datos.categoria = "todos";
        await this.listarGastosCaja(datos);
        this.movimientosSalida = this.movimientos;
      }
      this.cargaData = false;
    },
    async cargarMovmientosAnalizar(caso) {
      this.cargaData = true;
      var datos = {
        fecha: this.date,
        idusuario: this.idusuariolocalAnalizar,
        tipo: "ingreso",
        categoria: "todos",
        texto: this.textodefectoAnalizar,
      };
      if (this.categoria) {
        datos.categoria = "todos";
      } else {
        datos.categoria = "individual";
      }
      console.log(datos);
      if(caso == "entrada"){
        await this.listarTransferenciasCaja(datos);
        this.movimientosCajasEntrada = this.movimientos;
      } else {
        datos.tipo = "egreso";
        await this.listarTransferenciasCaja(datos);
        this.movimientosCajasSalida = this.movimientos;
      }
      
      
      this.cargaData = false;
    },
    async seleccionarCaja(item) {
      console.log(item)
      this.actualizarIdCaja(item);
      switch (item) {
        case "1":
          this.textodefecto = "CAJA1";
          break;
        case "3":
          this.textodefecto = "CAJA2";
          break;
        case "4":
          this.textodefecto = "CAJA3";
          break;
        case "5":
          this.textodefecto = "CAJA4";
          break;
        case "6":
          this.textodefecto = "CAJA5";
          break;
      }
      await this.cargarMovmientos();
      //console.log(this.cajas)
    },
    async seleccionarCaja1(item) {
      //console.log(item);
      this.cajapordefecto = item;
      this.actualizarIdCaja(item);
      switch (item) {
        case "1":
          this.textodefecto = "CAJA1";
          break;
        case "3":
          this.textodefecto = "CAJA2";
          break;
        case "4":
          this.textodefecto = "CAJA3";
          break;
        case "5":
          this.textodefecto = "CAJA4";
          break;
        case "6":
          this.textodefecto = "CAJA5";
          break;
      }
      await this.cargarMovmientos();
      //console.log(this.cajas)
    },
    async seleccionarMoneda(item) {
      console.log(item);
      this.actualizarIdMoneda(item);
      await this.cargarMovmientos();
      //console.log(this.cajas)
    },
    async seleccionarCajaAnalizar(item) {
      console.log(item);
      this.cajapordefecto = item
      //this.actualizarIdCaja(item);
      switch (item) {
        case "1":
          this.textodefectoAnalizar = "CAJA1";
          break;
        case "3":
          this.textodefectoAnalizar = "CAJA2";
          break;
        case "4":
          this.textodefectoAnalizar = "ELMER";
          break;
        case "5":
          this.textodefectoAnalizar = "EMERSON";
          break;
        case "6":
          this.textodefectoAnalizar = "CAJA5";
          break;
      }
      await this.cargarMovmientosAnalizar("salida");
      //console.log(this.cajas)
    },
    async seleccionarCajaAnalizar2(item) {
      console.log(item);
      this.idusuariolocalAnalizar = item;
      //this.actualizarIdCaja(item);
      switch (item) {
        case "3":
          this.textodefectoAnalizar = "CAJA1";
          break;
        case "4":
          this.textodefectoAnalizar = "CAJA2";
          break;
        case "5":
          this.textodefectoAnalizar = "CAJA3";
          break;
        case "6":
          this.textodefectoAnalizar = "CAJA4";
          break;
        case "7":
          this.textodefectoAnalizar = "CAJA5";
          break;
      }
      await this.cargarMovmientosAnalizar("entrada");
      //console.log(this.cajas)
    },
    async cambiarCategoria() {
      if (this.categoria) {
        this.textoCategoria = "Mostrar todos";
      } else {
        this.textoCategoria = "Mostrar por caja";
      }
      await this.cargarMovmientos();
    },
    async actualizarDatos() {
      await this.cargarMovmientos();
    },
    numeroComas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    async fechadatos() {
      await this.actualizarDatos();
    },
    getColor(accion) {
      let color = "";
      switch (accion) {
        case "DE":
        case "ZD":
          color = "blue";
          break;
        case "PR":
        case "ZP":
          color = "orange";
          break;
        case "RE":
        case "ZR":
          color = "red";
          break;
        default:
          color = "green";
          break;
      }
      return color;
    },
    incremento() {
      this.margentext = (parseFloat(this.margentext) + 0.01).toFixed(2);
      const datos = {
        margen: this.margentext,
      };
      localStorage.setItem("datos", JSON.stringify(datos));
    },
    decremento() {
      if (this.margentext > 0)
        this.margentext = (parseFloat(this.margentext) - 0.01).toFixed(2);
      const datos = {
        margen: this.margentext,
      };
      localStorage.setItem("datos", JSON.stringify(datos));
    },
    async actualizarEstado(item) {
     // console.log(item.estado)
      //console.log(item.codigo.substr(2))
      var datos = {
        estado: item.estado,
        idmovimiento: item.codigo.substr(2),
        idusuario: this.usuarios.id
      };

      await this.actualizarEstadoMovimientoTransferencia(datos)
      this.snackbar = true
      console.log(this.actualizarRes)
      if(this.actualizarRes == 0){
        this.textsnackbar = "Se actualizo el estado con exito!"
        this.colorsnackbar = 'success'
      } else {
        this.textsnackbar = "Error, intento nuevamente."
        this.colorsnackbar = 'error'
      }

    },
    async verItem(item, donde){
      this.disVerItem = true
      this.dialVer = true
      var datos = {
        idmovimiento: item.idmovimiento
      }
      this.idmovimientoNotas = item.idmovimiento
      this.notasText = item.notas
      this.datatableselec =  donde
      this.cargaDataNotas = true
      await this.listarNotas(datos)
      this.cargaDataNotas = false
      
      this.disVerItem = false
    },
    async cerrarVer () {
      this.dialVer = false
    },
    async modificarNotas () {
      var datos = {
        notas: this.notasText,
        idmovimiento: this.idmovimientoNotas
      };
      this.carActualizarNotas = true
      //setTimeout(() => (this.carActualizarNotas= false), 3000)
      await this.actualizarNotas(datos)
      if(this.datatableselec == 'principal'){
        await this.cargarMovmientos()
      } else {
        await this.cargarMovmientosAnalizar()
      }
      
      this.carActualizarNotas = false
    },
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
};
</script>